html {
  scrollbar-color: #365589 #343434;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #191b25;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track-piece {
  background-color: #343434;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #365589;
  border-radius: 6px;
}
